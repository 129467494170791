import React, { useState, useEffect, useRef } from "react";
import {
  Stage,
  Layer,
  Rect,
  Circle,
  RegularPolygon,
  Text,
  Transformer,
} from "react-konva";
import {
  CgShapeCircle,
  CgShapeTriangle,
  CgShapeSquare,
  CgRedo,
} from "react-icons/cg";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoColorPaletteOutline } from "react-icons/io5";
import Matter from "matter-js";
import Konva from "konva";
import { MdInfoOutline } from "react-icons/md";


const Shape = ({
  shapeProps,
  isSelected,
  onSelect,
  onDeselect,
  onChange,
  transformerRef,
  setButtonPosition,
  setBodyStatic,
  updateBodyProperties,
}) => {
  const shapeRef = useRef();

  useEffect(() => {
    if (isSelected && transformerRef.current && shapeRef.current) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected, transformerRef]);

  const handleDragStart = () => {
    document.body.style.cursor = "grabbing";
    setBodyStatic(shapeProps.id, true);
  };

  const handleDragEnd = (e) => {
    document.body.style.cursor = "default";
    setBodyStatic(shapeProps.id, false);
    handleDragMove(e);
  };

  const handleDragMove = (e) => {
    const node = e.target;
    const updatedShape = {
      ...shapeProps,
      x: node.x(),
      y: node.y(),
    };
    onChange(updatedShape);

    if (setButtonPosition) {
      const nodeWidth = node.width() * node.scaleX();
      const nodeHeight = node.height() * node.scaleY();
      const buttonX = node.x() + nodeWidth / 2 + 10;
      const buttonY = node.y() - nodeHeight / 2 - 40;

      setButtonPosition({ x: buttonX, y: buttonY });
    }
  };

  const handleMouseEnter = () => {
    document.body.style.cursor = "pointer";
  };

  const handleMouseLeave = () => {
    document.body.style.cursor = "default";
  };

  const handleTransformStart = () => {};

  const handleTransform = () => {
    const node = shapeRef.current;

    if (shapeProps.type === "text") {
      const scaleY = node.scaleY();

      node.scaleX(1);
      node.scaleY(1);

      const newFontSize = Math.max(node.fontSize() * scaleY, 5);

      node.fontSize(newFontSize);

      onChange({
        ...shapeProps,
        x: node.x(),
        y: node.y(),
        fontSize: newFontSize,
      });
    }
  };

  const handleTransformEnd = () => {
    const node = shapeRef.current;

    if (shapeProps.type !== "text") {
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      node.scaleX(1);
      node.scaleY(1);

      const updatedShape = {
        ...shapeProps,
        x: node.x(),
        y: node.y(),
        width:
          shapeProps.type === "rectangle"
            ? Math.max(5, node.width() * scaleX)
            : shapeProps.width,
        height:
          shapeProps.type === "rectangle"
            ? Math.max(5, node.height() * scaleY)
            : shapeProps.height,
        radius:
          shapeProps.type !== "rectangle"
            ? Math.max(5, shapeProps.radius * scaleX)
            : shapeProps.radius,
        rotation: node.rotation(),
      };

      onChange(updatedShape);
      updateBodyProperties(shapeProps.id, updatedShape);
    } else {
      const updatedShape = {
        ...shapeProps,
        x: node.x(),
        y: node.y(),
        rotation: node.rotation(),
      };
      onChange(updatedShape);
    }
  };

  return (
    <>
      {shapeProps.type === "rectangle" && (
        <Rect
          {...shapeProps}
          ref={shapeRef}
          offsetX={shapeProps.width / 2}
          offsetY={shapeProps.height / 2}
          draggable
          onClick={onSelect}
          onTap={onSelect}
          onDragStart={handleDragStart}
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTransformStart={handleTransformStart}
          onTransformEnd={handleTransformEnd}
        />
      )}
      {shapeProps.type === "circle" && (
        <Circle
          {...shapeProps}
          ref={shapeRef}
          draggable
          onClick={onSelect}
          onTap={onSelect}
          onDragStart={handleDragStart}
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTransformStart={handleTransformStart}
          onTransformEnd={handleTransformEnd}
        />
      )}
      {shapeProps.type === "triangle" && (
        <RegularPolygon
          {...shapeProps}
          ref={shapeRef}
          draggable
          sides={3}
          onClick={onSelect}
          onTap={onSelect}
          onDragStart={handleDragStart}
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTransformStart={handleTransformStart}
          onTransformEnd={handleTransformEnd}
        />
      )}
      {shapeProps.type === "text" && (
        <Text
          {...shapeProps}
          ref={shapeRef}
          draggable
          onClick={onSelect}
          onTap={onSelect}
          onDragMove={handleDragMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTransform={handleTransform}
          onTransformEnd={handleTransformEnd}
          align="center"
          verticalAlign="middle"
          wrap="word"
        />
      )}
      {isSelected && (
        <Transformer
          ref={transformerRef}
          enabledAnchors={
            shapeProps.type === "text"
              ? ["top-left", "top-right", "bottom-left", "bottom-right"]
              : undefined
          }
          boundBoxFunc={(oldBox, newBox) => {
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
          padding={0}
          ignoreStroke={true}
        />
      )}
    </>
  );
};

const InteractiveCanvas = ({ height = "70vh" }) => {
  const containerRef = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [shapes, setShapes] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedTool, setSelectedTool] = useState("circle");
  const transformerRef = useRef();
  const colors = ["#cbd5e1", "#334155", "#2563EB", "#93c5fd", "#020617"];
  const [buttonPosition, setButtonPosition] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const engine = useRef(null);
  const world = useRef(null);

  const shapeBodyMap = useRef({});

  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        setCanvasWidth(containerRef.current.offsetWidth);
        setCanvasHeight(containerRef.current.offsetHeight);
      }
      setIsMobile(window.innerWidth < 768);
    };
    updateCanvasSize();
    window.addEventListener("resize", updateCanvasSize);
    return () => window.removeEventListener("resize", updateCanvasSize);
  }, []);

  const prevSelectedIdRef = useRef();

  useEffect(() => {
    const prevSelectedId = prevSelectedIdRef.current;

    if (prevSelectedId && prevSelectedId !== selectedId) {
      // Shape was deselected
      setBodyStatic(prevSelectedId, false);

      const shape = shapes.find((s) => s.id === prevSelectedId);
      const body = shapeBodyMap.current[prevSelectedId];

      if (shape && body) {
        Matter.Body.setPosition(body, { x: shape.x, y: shape.y });
        Matter.Body.setAngle(body, (shape.rotation * Math.PI) / 180);
        Matter.Body.setAngularVelocity(body, 0);
        Matter.Body.setVelocity(body, { x: 0, y: 0 });
      }
    }

    if (selectedId) {
      // Shape is selected
      setBodyStatic(selectedId, true);
    }

    prevSelectedIdRef.current = selectedId;
  }, [selectedId, shapes]);

  useEffect(() => {
    engine.current = Matter.Engine.create();
    world.current = engine.current.world;
    world.current.gravity.y = 1;

    const walls = [
      Matter.Bodies.rectangle(
        canvasWidth / 2,
        canvasHeight + 25,
        canvasWidth,
        50,
        {
          isStatic: true,
          friction: 1,
          restitution: 0,
        }
      ),
      Matter.Bodies.rectangle(-25, canvasHeight / 2, 50, canvasHeight, {
        isStatic: true,
      }),

      Matter.Bodies.rectangle(
        canvasWidth + 25,
        canvasHeight / 2,
        50,
        canvasHeight,
        {
          isStatic: true,
        }
      ),
    ];
    Matter.World.add(world.current, walls);

    const runner = Matter.Runner.create();
    Matter.Runner.run(runner, engine.current);

    return () => {
      Matter.Runner.stop(runner);
      engine.current = null;
      world.current = null;
    };
  }, [canvasWidth, canvasHeight]);

  useEffect(() => {
    const textShape1 = {
      id: "text-1",
      type: "text",
      text: "Product Designer that builds",
      x: 0,
      y: (canvasHeight - 140) / 2,
      fontSize: 52,
      fontFamily: "ClashRegular",
      draggable: true,
      rotation: 0,
      align: "center",
      verticalAlign: "middle",
      fill: "#2563EB",
      wrap: "word",
    };

    const textShape2 = {
      id: "text-2",
      type: "text",
      text: "and so can you by clicking around",
      x: 0,
      y: canvasHeight - 300,
      fontSize: 28,
      fontFamily: "ClashRegular",
      draggable: true,
      rotation: 0,
      align: "center",
      verticalAlign: "middle",
      fill: "#475569",
      wrap: "word",
    };

    const tempTextNode1 = new Konva.Text(textShape1);
    const textWidth1 = tempTextNode1.width();

    const tempTextNode2 = new Konva.Text(textShape2);
    const textWidth2 = tempTextNode2.width();

    textShape1.x = (canvasWidth - textWidth1) / 2;
    textShape2.x = (canvasWidth - textWidth2) / 2;

    setShapes([textShape1, textShape2]);
  }, [canvasWidth, canvasHeight]);

  const selectedIdRef = useRef(selectedId);

  useEffect(() => {
    selectedIdRef.current = selectedId;
  }, [selectedId]);

  useEffect(() => {
    let animationFrameId;

    const update = () => {
      Matter.Engine.update(engine.current);

      setShapes((prevShapes) =>
        prevShapes.map((shape) => {
          const body = shapeBodyMap.current[shape.id];
          if (body) {
            if (shape.id !== selectedIdRef.current) {
              return {
                ...shape,
                x: body.position.x,
                y: body.position.y,
                rotation: (body.angle * 180) / Math.PI,
              };
            } else {
              return shape;
            }
          } else {
            return shape;
          }
        })
      );

      animationFrameId = requestAnimationFrame(update);
    };

    update();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  const setBodyStatic = (shapeId, isStatic) => {
    const body = shapeBodyMap.current[shapeId];
    if (body) {
      Matter.Body.setStatic(body, isStatic);
      Matter.Body.setAngularVelocity(body, 0);
      Matter.Body.setVelocity(body, { x: 0, y: 0 });
    }
  };

  const updateBodyProperties = (shapeId, newShapeProps, isStatic = false) => {
    const body = shapeBodyMap.current[shapeId];
    if (body) {
      Matter.World.remove(world.current, body);

      let newBody;

      if (newShapeProps.type === "rectangle") {
        newBody = Matter.Bodies.rectangle(
          newShapeProps.x,
          newShapeProps.y,
          newShapeProps.width,
          newShapeProps.height,
          {
            restitution: 0.5,
            friction: 0.5,
            density: 0.01,
            angle: (newShapeProps.rotation * Math.PI) / 180,
          }
        );
      } else if (newShapeProps.type === "circle") {
        newBody = Matter.Bodies.circle(
          newShapeProps.x,
          newShapeProps.y,
          newShapeProps.radius,
          {
            restitution: 0.9,
            friction: 0.2,
            density: 0.001,
            frictionAir: 0.001,
          }
        );
      } else if (newShapeProps.type === "triangle") {
        const trianglePath = createTrianglePath(
          newShapeProps.x,
          newShapeProps.y,
          newShapeProps.radius
        );
        newBody = Matter.Bodies.fromVertices(
          newShapeProps.x,
          newShapeProps.y,
          trianglePath,
          {
            restitution: 0.4,
            friction: 0.4,
            density: 0.005,
          }
        );
      }

      Matter.Body.setStatic(newBody, isStatic);

      Matter.World.add(world.current, newBody);

      shapeBodyMap.current[shapeId] = newBody;
    }
  };

  const createTrianglePath = (x, y, radius) => {
    const angleOffset = -Math.PI / 2;
    const path = [];
    for (let i = 0; i < 3; i++) {
      const angle = angleOffset + (i * (2 * Math.PI)) / 3;
      const pointX = x + radius * Math.cos(angle);
      const pointY = y + radius * Math.sin(angle);
      path.push({ x: pointX, y: pointY });
    }
    return [path];
  };

  const handleStageMouseDown = (e) => {
    if (e.target === e.target.getStage()) {
      if (selectedId) {
        setSelectedId(null);
        setButtonPosition(null);
      }

      const pointerPosition = e.target.getPointerPosition();
      const size = 100;

      const randomColor = colors[Math.floor(Math.random() * colors.length)];

      let newShape = {
        id: `shape-${Date.now()}`,
        type: selectedTool,
        x: pointerPosition.x,
        y: pointerPosition.y,
        width: size,
        height: size,
        radius: selectedTool === "triangle" ? size / Math.sqrt(3) : size / 2,
        fill: randomColor,
        draggable: true,
        rotation: 0,
      };

      let body;
      if (selectedTool === "rectangle") {
        body = Matter.Bodies.rectangle(
          pointerPosition.x,
          pointerPosition.y,
          newShape.width,
          newShape.height,
          {
            restitution: 0.5,
            friction: 0.5,
            density: 0.01,
            angle: (newShape.rotation * Math.PI) / 180,
          }
        );
      } else if (selectedTool === "circle") {
        body = Matter.Bodies.circle(
          pointerPosition.x,
          pointerPosition.y,
          newShape.radius,
          {
            restitution: 0.9,
            friction: 0.2,
            density: 0.001,
            frictionAir: 0.001,
          }
        );
      } else if (selectedTool === "triangle") {
        const trianglePath = createTrianglePath(
          pointerPosition.x,
          pointerPosition.y,
          newShape.radius
        );
        body = Matter.Bodies.fromVertices(
          pointerPosition.x,
          pointerPosition.y,
          trianglePath,
          {
            restitution: 0.4,
            friction: 0.4,
            density: 0.005,
          }
        );
      }

      Matter.World.add(world.current, body);
      shapeBodyMap.current[newShape.id] = body;

      setShapes((prevShapes) => [...prevShapes, newShape]);
    }
  };

  const resetCanvas = () => {
    Matter.World.clear(world.current, false);
    shapeBodyMap.current = {};

    const walls = [
      Matter.Bodies.rectangle(
        canvasWidth / 2,
        canvasHeight + 25,
        canvasWidth,
        50,
        {
          isStatic: true,
          friction: 1,
          restitution: 0,
        }
      ),
      Matter.Bodies.rectangle(-25, canvasHeight / 2, 50, canvasHeight, {
        isStatic: true,
      }),
      Matter.Bodies.rectangle(
        canvasWidth + 25,
        canvasHeight / 2,
        50,
        canvasHeight,
        {
          isStatic: true,
        }
      ),
    ];
    Matter.World.add(world.current, walls);

    const textShape1 = {
      id: "text-1",
      type: "text",
      text: "Product Designer that builds",
      x: 0,
      y: (canvasHeight - 140) / 2,
      fontSize: 52,
      fontFamily: "ClashRegular",
      draggable: true,
      rotation: 0,
      align: "center",
      verticalAlign: "middle",
      fill: "#2563EB",
      wrap: "word",
    };

    const textShape2 = {
      id: "text-2",
      type: "text",
      text: "and so can you by clicking around",
      x: 0,
      y: canvasHeight - 300,
      fontSize: 28,
      fontFamily: "ClashRegular",
      draggable: true,
      rotation: 0,
      align: "center",
      verticalAlign: "middle",
      fill: "#475569",
      wrap: "word",
    };

    const tempTextNode1 = new Konva.Text(textShape1);
    const textWidth1 = tempTextNode1.width();

    const tempTextNode2 = new Konva.Text(textShape2);
    const textWidth2 = tempTextNode2.width();

    textShape1.x = (canvasWidth - textWidth1) / 2;
    textShape2.x = (canvasWidth - textWidth2) / 2;

    setShapes([textShape1, textShape2]);

    setSelectedId(null);
    setButtonPosition(null);
  };

  const removeShape = (id) => {
    const body = shapeBodyMap.current[id];
    if (body) {
      Matter.World.remove(world.current, body);
      delete shapeBodyMap.current[id];
    }
    setShapes((prevShapes) => prevShapes.filter((shape) => shape.id !== id));
    setButtonPosition(null);
    if (selectedId === id) {
      setSelectedId(null);
    }
  };

  const changeShapeColor = (id) => {
    setShapes((prevShapes) =>
      prevShapes.map((shape) => {
        if (shape.id === id) {
          const currentColorIndex = colors.indexOf(shape.fill);
          const nextColorIndex = (currentColorIndex + 1) % colors.length;
          return { ...shape, fill: colors[nextColorIndex] };
        }
        return shape;
      })
    );
  };

  return (
    <div className="relative md:max-w-3xl px-0 md:px-4 lg:mx-auto lg:max-w-4xl xl:max-w-6xl mx-auto">
      {isMobile ? (
        <div className="p-4 text-center bg-white">
          <h1 className="text-6xl font-clash text-blue-600 mb-4 leading-tight">
            Product Designer that builds
          </h1>
          <div className="flex flex-row bg-blue-50 gap-4 rounded-xl py-4 px-4 mx-4 items-center border border-blue-300">
          <MdInfoOutline size={40} className="text-blue-600"/>
          <p className="text-lg text-left font-clash text-slate-600">
            For the full experience, view this portfolio on a desktop PC.
          </p>
          </div>
        </div>
      ) : (
        <div
          className="relative max-w-6xl mx-auto bg-white border border-slate-200 rounded-2xl overflow-hidden"
          style={{ height }}
          ref={containerRef}
        >
          <div className="absolute inset-0 pattern-dots pattern-slate-300 pattern-bg-white pattern-size-4 pointer-events-none z-0"></div>
          <div className="relative h-full z-10">
            <Stage
              width={canvasWidth}
              height={canvasHeight}
              onMouseDown={handleStageMouseDown}
              onTouchStart={handleStageMouseDown}
            >
              <Layer>
                {shapes.map((shape, i) => (
                  <Shape
                    key={shape.id}
                    shapeProps={shape}
                    isSelected={shape.id === selectedId}
                    transformerRef={transformerRef}
                    setButtonPosition={setButtonPosition}
                    onSelect={() => {
                      setSelectedId(shape.id);

                      const nodeWidth =
                        shape.type === "text"
                          ? new Konva.Text(shape).width()
                          : shape.width ||
                            (shape.radius ? shape.radius * 2 : 0);
                      const nodeHeight =
                        shape.type === "text"
                          ? new Konva.Text(shape).height()
                          : shape.height ||
                            (shape.radius ? shape.radius * 2 : 0);

                      const buttonX = shape.x + nodeWidth / 2 + 10;
                      const buttonY = shape.y - nodeHeight / 2 - 40;
                      setButtonPosition({ x: buttonX, y: buttonY });
                    }}
                    onDeselect={() => {
                      setSelectedId(null);
                      setButtonPosition(null);
                    }}
                    onChange={(newAttrs) => {
                      const updatedShapes = shapes.slice();
                      updatedShapes[i] = newAttrs;
                      setShapes(updatedShapes);
                      if (shape.type !== "text") {
                        updateBodyProperties(shape.id, newAttrs);
                      }
                    }}
                    setBodyStatic={setBodyStatic}
                    updateBodyProperties={updateBodyProperties}
                  />
                ))}
              </Layer>
            </Stage>
            {buttonPosition && selectedId && (
              <div
                style={{
                  position: "absolute",
                  top: buttonPosition.y,
                  left: buttonPosition.x,
                }}
              >
                <button
                  onClick={() => removeShape(selectedId)}
                  className="p-2 bg-slate-200 hover:bg-slate-100 rounded-full"
                >
                  <FaRegTrashAlt size={20} />
                </button>
                <button
                  onClick={() => changeShapeColor(selectedId)}
                  className="p-2 bg-slate-200 hover:bg-slate-100 rounded-full ml-2"
                >
                  <IoColorPaletteOutline size={20} />
                </button>
              </div>
            )}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white border border-slate-200 rounded-full p-2 flex space-x-2">
              <button
                onClick={() => setSelectedTool("circle")}
                className={`p-2 rounded ${
                  selectedTool === "circle"
                    ? "bg-blue-600 text-white rounded-full"
                    : "bg-slate-200 text-slate-900 hover:bg-slate-100 rounded-full"
                }`}
              >
                <CgShapeCircle size={24} />
              </button>
              <button
                onClick={() => setSelectedTool("rectangle")}
                className={`p-2 rounded ${
                  selectedTool === "rectangle"
                    ? "bg-blue-600 text-white rounded-full"
                    : "bg-slate-200 text-slate-900 hover:bg-slate-100 rounded-full"
                }`}
              >
                <CgShapeSquare size={24} />
              </button>
              <button
                onClick={() => setSelectedTool("triangle")}
                className={`p-2 rounded ${
                  selectedTool === "triangle"
                    ? "bg-blue-600 text-white rounded-full"
                    : "bg-slate-200 text-slate-900 hover:bg-slate-100 rounded-full"
                }`}
              >
                <CgShapeTriangle size={24} />
              </button>
              <button
                onClick={resetCanvas}
                className="p-2 bg-slate-200 text-slate-900 hover:bg-slate-100 rounded-full"
              >
                <CgRedo size={24} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InteractiveCanvas;
