import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiX } from "react-icons/fi";

const VideoViewerCard = ({ videoUrl, title, tool }) => {
  const [isViewerOpen, setViewerOpen] = useState(false);

  useEffect(() => {
    if (isViewerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset on unmount
    };
  }, [isViewerOpen]);

  const handleCardClick = () => setViewerOpen(true);
  const handleCloseViewer = () => setViewerOpen(false);

  return (
    <div className="relative flex items-center justify-center">
      {/* Card */}
      <div
        className={`${
          isViewerOpen ? "opacity-25" : "opacity-100"
        } transition-opacity duration-300`}
      >
        <div onClick={handleCardClick} className="max-w-3xl h-72">
          <div className="cursor-pointer transition-transform,shadow duration-300 ease-in-out transform md:hover:scale-95 bg-white border border-slate-200 rounded-2xl   md:hover:border-blue-600 md:hover:transition-transform md:hover:duration-300 overflow-hidden">
            <video
              src={videoUrl}
              className="w-full h-full object-cover rounded-lg"
              muted
              loop
              playsInline
              autoPlay
            />
          </div>
          <div className="mt-2">
            <p className="text-left text-balance font-medium">{title}</p>
            <p className="text-left text-sm text-slate-600">Tool: {tool}</p>
          </div>
        </div>
      </div>

      {/* Video Viewer */}
      <AnimatePresence>
        {isViewerOpen && (
          <motion.div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 z-40 flex items-center justify-center"
            
            onClick={handleCloseViewer} // Close viewer when clicking on overlay
          >
            {/* Video Viewer Content */}
            <motion.video
              src={videoUrl}
              className="max-w-full max-h-full object-contain"
              autoPlay
              loop
              muted
              playsInline
              
              onClick={(e) => e.stopPropagation()} // Prevent overlay click from closing viewer
            />

            {/* Close Button */}
            <button
              onClick={handleCloseViewer}
              className="absolute top-4 right-4 p-2 bg-gray-800 text-white rounded-full hover:bg-gray-700"
              aria-label="Close Viewer"
            >
              <FiX size={24} />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default VideoViewerCard;
