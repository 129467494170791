import React, { useState, useEffect } from "react";
import CaseStudyCard from "./components/CaseStudyCard";
import ImageViewerCard from "./components/ImageViewerCard";
import InteractiveCanvas from "./components/InteractiveCanvas";
import imgInstallments from "./assets/imgInstallments.png";
import imgpfp from "./assets/imgpfp.png";
import imgbcn from "./assets/imgbcn.png";
import car from "./assets/car.jpg";
import imgProfile from "./assets/imgprofile.png";
import imgCar from "./assets/imgcar.png";
import imgCoupons from "./assets/imgcoupons.png";
import gameui from "./assets/gameui.jpg";
import VideoViewerCard from "./components/VideoViewerCard";
import kubernetes from "./assets/kubernetes.mp4";
import orb from "./assets/orb.mp4";
import coins from "./assets/coins.mp4";
import saas from "./assets/saas.jpg";
import bcn from "./assets/bcn.jpg";
import cartoon from "./assets/cartoon.jpg";
import {
  MdOutlineFileDownload,
  MdContentCopy,
  MdArrowOutward,MdKeyboardArrowUp 
} from "react-icons/md";

const installmentsPDF = process.env.PUBLIC_URL + "/InstallmentsCaseStudy.pdf";
const loginPDF = process.env.PUBLIC_URL + "/LoginCaseStudy.pdf";
const couponsPDF = process.env.PUBLIC_URL + "/CouponsCaseStudy.pdf";
function App() {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/oscar-rabadan-fuertes/", "_blank");
  };
  const [copied, setCopied] = useState(false);
  const downloadFile = () => {
    const fileUrl =
      "https://drive.google.com/uc?export=download&id=1QjsDikeppGoEKx4FVERZHEiFaaCc_9zC";
    window.location.href = fileUrl;
  };

  const copyToClipboard = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      // Modern API for secure environments
      navigator.clipboard.writeText("orabadanux@gmail.com").then(
        () => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Revert after 2 seconds
        },
        (err) => {
          console.error("Could not copy text: ", err);
        }
      );
    } else {
      // Fallback for unsupported environments
      const textArea = document.createElement("textarea");
      textArea.value = "orabadanux@gmail.com";
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy"); // Legacy API
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Revert after 2 seconds
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    }
  };
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="App bg-white relative">
      {showScrollToTop && (
        <button
          className="z-20 fixed bottom-2 right-2 xl:bottom-4 xl:right-4 bg-blue-600 text-white p-2 xl:p-2 rounded-full shadow-lg hover:bg-blue-800 transition-transform duration-300 ease-in-out"
          onClick={scrollToTop}
        >
          <MdKeyboardArrowUp className="size-8 xl:size-12"/>
        </button>
      )}
      <div className="w-full px-8 md:px-4 xl:px-8 pt-4 pb-4">
        <div className="flex flex-row items-center justify-center gap-x-8">
          <div className="flex flex-row items-center gap-x-2 ">
            <img
              alt=""
              src={imgProfile}
              className="max-w-lg rounded-xl h-fit"
            />
            <p className="font-clash text-base text-slate-500">Oscar Rabadán</p>
          </div>
          <div className="hidden md:flex flex-row items-center gap-x-8">
            <p
              className="cursor-pointer font-clash text-base text-slate-500 hover:text-blue-600"
              onClick={() => scrollToSection("case-studies")}
            >
              Case studies
            </p>
            <p
              className="cursor-pointer  font-clash text-base text-slate-500 hover:text-blue-600"
              onClick={() => scrollToSection("gallery")}
            >
              Gallery
            </p>
            <p
              className="cursor-pointer  font-clash text-base text-slate-500 hover:text-blue-600"
              onClick={() => scrollToSection("about-me")}
            >
              About me
            </p>
            <p
              className="cursor-pointer  font-clash text-base text-slate-500 hover:text-blue-600"
              onClick={() => scrollToSection("contact")}
            >
              Contact
            </p>
          </div>
          <button
            className="cursor-pointer flex flex-row items-center transition-transform duration-300 ease-in-out hover:scale-95 hover:transition-transform hover:duration-300 gap-2 font-clash text-base bg-blue-600 text-white hover:bg-blue-800 py-2 px-4 rounded-full"
            onClick={downloadFile}
          >
            <span>Resume</span>
            <MdOutlineFileDownload className="text-white" size={20} />
          </button>
        </div>
      </div>

      <div className="mb-24 mt-12">
        <InteractiveCanvas height="70vh" />
      </div>

      <div className="bg-slate-50 pb-16">
        <div
          id="case-studies"
          className="w-full flex flex-col items-center justify-center mx-auto pt-12 xl:pt-16"
        >
          <h1 className="font-clash text-xl text-slate-600 mb-12 xl:mb-12">
            CASE STUDIES
          </h1>
        </div>

        <div className="max-w-6xl mx-4 sm:mx-4 sm:space-y-8 md:space-y-12 md:mb-0 sm:mb-2 xl:mx-auto xl:space-y-4 xl:mb-32">
          <div className="flex flex-col sm:flex-row xl:flex-row sm:space-x-6 items-center justify-center xl:space-x-12 gap-y-12">
            <CaseStudyCard
              pdfFile={installmentsPDF}
              imageUrl={imgInstallments}
              title="Achieving 114% Revenue Growth with a Installment Funnel Redesign"
              readingTime="7"
            />
            <CaseStudyCard
              pdfFile={loginPDF}
              imageUrl={imgCar}
              title="Boosting Login Success by 31% in a Vehicle App with a Simplified Redesign"
              readingTime="5"
            />
          </div>

          <div className="flex flex-col xl:flex-row sm:items-start items-center xl:pt-28">
            <div className="w-full sm:w-full sm:max-w-[calc((100%-1.5rem)/2)] sm:mt-0 xl:max-w-[calc((100%-3rem)/2)] mt-12">
              {" "}
              <CaseStudyCard
                pdfFile={couponsPDF}
                imageUrl={imgCoupons}
                title="Driving 85% Adoption by Enabling Sellers to Create and Manage Coupon Campaigns"
                readingTime="5"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        id="gallery"
        className="w-full flex flex-col items-center justify-center mx-auto pt-24 xl:pt-24"
      >
        <h1 className="font-clash text-xl text-slate-600 mb-12 xl:mb-12">
          GALLERY
        </h1>
      </div>
      <div className="max-w-6xl mx-4 xl:mx-auto xl:space-y-8 mb-24 xl:mb-48 ">
        <div className="flex flex-col sm:flex-row sm:space-x-6 xl:flex-row items-center justify-center  xl:space-x-12 gap-y-24">
          <VideoViewerCard
            videoUrl={coins}
            title="3D Currencies Animation"
            tool="Spline, Figma"
          />
          <ImageViewerCard
            imageUrl={car}
            title="Remote Vehicle App UI Design"
            tool="Figma"
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:space-x-6 xl:flex-row items-center justify-center lg:mt-40 mt-24 md:mt-20 gap-y-24 xl:space-x-12 xl:pt-48">
          <ImageViewerCard
            imageUrl={saas}
            title="SaaS UI Design"
            tool="Spline"
          />
          <ImageViewerCard
            imageUrl={gameui}
            title="Mobile game UI design"
            tool="Photoshop, Figma"
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:space-x-6 xl:flex-row items-center justify-center lg:mt-40 mt-24 md:mt-20 gap-y-24 xl:space-x-12 xl:pt-48">
          <VideoViewerCard
            videoUrl={orb}
            title="3D AI Orb animation"
            tool="Spline, Figma"
          />
          <ImageViewerCard
            imageUrl={cartoon}
            title="UX-Themed Retro Cartoon illustrations"
            tool="Figma"
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:space-x-6 xl:flex-row items-center justify-center lg:mt-40 mt-24 md:mt-20 gap-y-24 xl:space-x-12 xl:pt-48 lg:pb-24 md:pb-2 pb-12">
          <ImageViewerCard
            imageUrl={bcn}
            title="Recycling App Onboarding UI Design"
            tool="Figma"
          />
          <VideoViewerCard
            videoUrl={kubernetes}
            title="3D Kubernetes animation"
            tool="Spline, Figma"
          />
        </div>
      </div>

      <div
        id="about-me"
        className="w-full bg-slate-900 items-center justify-center mx-auto pb-32"
      >
        <p className="w-full flex flex-row font-clash text-base items-center justify-center mx-auto text-slate-200 pt-16 mb-8 xl:mb-24">
          ABOUT ME
        </p>
        <div className="flex flex-col xl:flex-row max-w-6xl items-center xl:items-start xl:justify-start xl:mx-auto sm:mx-8 mx-4 xl:space-x-12">
          <div className=" flex flex-col">
            <img
              alt=""
              src={imgpfp}
              className=" mx-auto sm:max-w-md xl:max-w-lg rounded-xl xl:h-fit"
            ></img>
            <img
              alt=""
              src={imgbcn}
              className="hidden xl:flex max-w-lg rounded-xl h-fit mt-4"
            ></img>
          </div>
          <div className="flex flex-col space-y-4 xl:space-y-6">
            <p className="mt-8  xl:mt-0 font-sans text-xl font-extralight text-slate-300">
              Hello,
            </p>
            <p className="mt-8  xl:mt-0 font-sans text-xl font-extralight text-slate-300">
              I'm Oscar, a Product Designer from Barcelona with a holistic
              approach to creating meaningful experiences.
            </p>
            <p className="mt-8  xl:mt-0 font-sans text-xl font-extralight text-slate-300">
              While the product manager owns the product, I see myself as the
              owner of the experience, bringing together insights and expertise
              from all teams to craft solutions that meet user needs, business
              goals, and technical realities.
            </p>
            <p className="mt-8  xl:mt-0 font-sans text-xl font-extralight text-slate-300">
              For me, design is about shaping the entire journey. I collaborate
              with engineers to ensure feasibility, data analysts to uncover
              insights, and marketers to align user journeys.
            </p>
            <p className="mt-8  xl:mt-0 font-sans text-xl font-extralight text-slate-300">
              Research drives my process, blending interviews, benchmarks, and
              data analysis to create thoughtful and effective designs.
            </p>
            <p className="mt-8  xl:mt-0 font-sans text-xl font-extralight text-slate-300">
              Clear communication is key in my work. I align teams, negotiate
              priorities, and adapt to project needs—whether it's a quick
              data-driven redesign or a complete experience from wireframes to
              final UI. I prioritize accessibility and ensure smooth
              implementation through detailed documentation and collaboration
              with developers.
            </p>
            <p className="mt-8  xl:mt-0 font-sans text-xl font-extralight text-slate-300">
              With coding skills in React, HTML, CSS, and JavaScript, I bridge
              design and development, ensuring seamless execution. I'm
              passionate about solving problems, connecting teams, and owning
              the experience to deliver impactful designs.
            </p>
          </div>
        </div>
      </div>
      
      <div id="contact" className="w-full bg-slate-900 pb-2">
        <h1 className="font-clash text-base text-white mb-12 text-center">
          CONTACT
        </h1>
        <div className="w-full">
          <div className="flex flex-col xl:flex-row items-center justify-center space-y-8 xl:space-y-0 xl:gap-x-8">
          <button
      className="cursor-pointer flex flex-row items-center gap-2 font-clash text-base bg-blue-600 text-white hover:bg-blue-800 py-2 px-4 rounded-full transition-transform duration-300 ease-in-out hover:scale-95"
      onClick={copyToClipboard}
    >
      <span
        className={`transition-opacity duration-500 ${
          copied ? "opacity-0" : "opacity-100"
        }`}
      >
        orabadanux@gmail.com
      </span>
      <span
        className={`absolute transition-opacity duration-500 ${
          copied ? "opacity-100" : "opacity-0"
        }`}
      >
        Copied!
      </span>
      <MdContentCopy className="text-white" size={20} />
    </button>
            <button
              className="cursor-pointer flex flex-row items-center transition-transform duration-300 ease-in-out hover:scale-95 hover:transition-transform hover:duration-300 gap-2 font-clash text-base bg-blue-600 text-white hover:bg-blue-800 py-2 px-4 rounded-full"
              onClick={openLinkedIn}
            >
              <span className="relative top-[1px]">LinkedIn</span>
              <MdArrowOutward className="text-white" size={20} />
            </button>
            <button
              className="cursor-pointer flex flex-row items-center transition-transform duration-300 ease-in-out hover:scale-95 hover:transition-transform hover:duration-300 gap-2 font-clash text-base bg-blue-600 text-white hover:bg-blue-800 py-2 px-4 rounded-full"
              onClick={downloadFile}
            >
              <span className="relative top-[1px]">Resume</span>
              <MdOutlineFileDownload className="text-white" size={20} />
            </button>
          </div>
          <div className="flex flex-row items-center justify-center mt-24">
          <p
            className="font-clash text-sm text-slate-300"
          
          >
            © 2024 Oscar Rabadán Fuertes.
          </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
